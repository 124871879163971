<template>
  <div
    style="background-size: cover; min-height:100%;"
  >
    <div :style="`background: ${getBackGroud()}; padding:1.1rem 2rem; text-align: center`">
      <b-img
        v-if="checkCoca()"
        src="@/assets/images/logo/coca_logo.png"
        alt="Logo"
        style="max-width: 200px"
      />
      <b-img
        v-else
        src="@/assets/images/logo/logo-brandme.png"
        alt="Logo"
        style="max-width: 100px;"
      />
    </div>
    <b-row
      class="ml-2 mr-2 mt-2"
    >
      <b-col
        class="heightCover"
      >
        <b-card
          :img-src="cover"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile heightCover"
          style="position: relative;"
        >
          <div
            class="profile-image-wrapper"
          >
            <div class="profile-image p-0">
              <b-avatar
                size="164"
                variant="light"
                :src="logo"
              />
            </div>
          </div>
          <div
            style="position: relative; display: inline-block"
          >
            <h1><strong>{{ name }}</strong></h1>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="ml-2 mr-2"
    >
      <b-col>
        <b-card>
          <b-row>
            <b-col
              md="12"
              lg="3"
              class="text-center"
            >
              <b-avatar
                size="164"
                variant="light"
                v-if="campaignInfo.campaign_influencer"
                :src="getImage(campaignInfo.campaign_influencer.influencer)"
              />
            </b-col>
            <b-col
              md="12"
              lg="9"
              style="display: flex; align-items: center;"
            >
              <b-row
                style="width: 100%;"
              >
                <b-col
                  md="12"
                  v-if="campaignInfo.campaign_influencer"
                >
                    <h2><strong>{{ campaignInfo.campaign_influencer.influencer.username }}</strong></h2>
                </b-col>
                <b-col
                  md="6"
                  lg="5"
                  style="display: flex; align-items: center;"
                >
                  <div>
                    <h4>Número de contenidos: {{ sumContent }} </h4>
                  </div>
                </b-col>
                <b-col
                  md="6"
                  lg="5"
                  style="display: flex; align-items: center;"
                >
                  <div>
                    <h4>Duración: {{campaignInfo.init_date}} / {{campaignInfo.end_date}}</h4>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-tabs
      class="pr-2 pl-2 pb-4"
    >
      <b-tab
        active
        title="Brief"
      >
        <b-card class="card-profile heightCover">
          <h3 class="blueC">Descripción</h3>
          <div class="plusGrand" v-html="campaignInfo.description"></div>
        </b-card>
        <b-card
          v-for="(brief, index) in getCustomFields()"
          :key="index"
        >
          <div v-if="brief.show_link == true">
              <h3 class="blueC">{{brief.front_name}}</h3>
              <div class="plusGrand" v-html="brief.form_value"></div>
          </div>
        </b-card>
      </b-tab>
      <b-tab
        title="Contenidos"
        v-if="campaignInfo.campaign_influencer"
      >
        <b-card class="mb-base"
          v-for="(content,x) in campaignInfo.campaign_influencer.content"
          :key="x"
        >
          <b-row>
            <b-col
              md="12"
              lg="2"
              class="textVertical"
              :key="componentKey"
            >
              <div v-show="selectedRed[x].title == 'Instagram'" class="texto-vertical-2 instagram ml-auto" style="font-size:65px;">Instagram</div>
              <div v-show="selectedRed[x].title == 'Tiktok'" class="texto-vertical-2 tiktok ml-auto" style="font-size:65px;">Tiktok</div>
              <div v-show="selectedRed[x].title == 'Youtube'" class="texto-vertical-2 youtube ml-auto" style="font-size:65px;">Youtube</div>
              <div v-show="selectedRed[x].title == 'Facebook'" class="texto-vertical-2 facebook ml-auto" style="font-size:65px;">Facebook</div>
              <div v-show="selectedRed[x].title == 'LinkedIn'" class="texto-vertical-2 linkedin ml-auto" style="font-size:65px;">LinkedIn</div>
              <div v-show="selectedRed[x].title == 'Twitter'" class="texto-vertical-2 twitter ml-auto" style="font-size:65px;">Twitter</div>
              <div v-show="selectedRed[x].title == 'Spotify'" class="texto-vertical-2 spotify ml-auto" style="font-size:65px;">Spotify</div>
              <div v-show="selectedRed[x].title == 'Pinterest'" class="texto-vertical-2 pinterest ml-auto" style="font-size:65px;">Pinterest</div>
              <div v-show="selectedRed[x].title == 'Twitch'" class="texto-vertical-2 twitch ml-auto" style="font-size:65px;">Twitch</div>
              <div v-show="selectedRed[x].title == 'Blog'" class="texto-vertical-2 blog ml-auto" style="font-size:65px;">Blog</div>
              <div v-show="selectedRed[x].title == 'Snapchat'" class="texto-vertical-2 snapchat ml-auto" style="font-size:65px;">Snapchat</div>
            </b-col>
            <b-col
              md="12"
              lg="10"
            >
              <b-row>
                <b-col
                  md="12"
                  lg="6"
                  style="align-items: center;"
                >
                  <b-row>
                    <b-col
                      md="12"
                      lg="4"
                    >
                        <p class="mr-5">Cambiar red</p>
                    </b-col>
                    <b-col
                      md="12"
                      lg="8"
                    >
                      <v-select
                        @input="loadTypes(x)"
                        v-model="selectedRed[x]"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="optionsRed[x]"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  md="12"
                  lg="6"
                  style="align-items: center;"
                >
                  <b-row>
                    <b-col
                      md="12"
                      lg="4"
                    >
                        <p class="mr-5">Contenido</p>
                    </b-col>
                    <b-col
                      md="12"
                      lg="8"
                    >
                      <v-select
                        v-model="selectedType[x]"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                        label="title" 
                        :options="optionsType[x]"
                        @input="forceRerender"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- INSTAGRAM -->
              <b-col
                v-if="selectedRed[x].title == 'Instagram'"
                class="mt-3"
              >
                <div v-show="selectedType[x].title == 'Story'">
                    <div class="divider" />
                    <div
                      v-show="content.image_content == null"
                      class="mr-auto ml-auto"
                      style="width: 80%; display: flex;"
                    >
                      <b-form-file
                        placeholder="Cargar Story"
                        drop-placeholder="Drop file here..."
                        v-on:change="handleFileUpload(x)"
                        accept=".jpeg, .jpg, .png, .mp4"
                        ref="image_content"
                        style="width: 75%; margin-right: 5%"
                        browse-text="Buscar"
                      />
                      <b-button
                        variant="success"
                        @click="linkStory(content.uuid,x )"
                        style="width: 20%"
                      >
                        Obtener Story
                      </b-button>
                    </div>
                    <div v-show="content.image_content != null" class="vx-col mr-auto ml-auto text-center">
                      <div>
                        <video v-show="content.image_content != null && getUrl(content.image_content) == 'mp4'" style="width: 50%;" :src="content.image_content" controls></video>
                        <video v-show="content.image_content != null && getUrl(content.image_content) == 'MP4'" style="width: 50%;" :src="content.image_content" controls></video>
                        <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'png'" style="width: 35%;" :src="content.image_content">
                        <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'PNG'" style="width: 35%;" :src="content.image_content">
                        <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'jpg'" style="width: 35%;" :src="content.image_content">
                        <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'JPG'" style="width: 35%;" :src="content.image_content">
                        <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'jpeg'" style="width: 35%;" :src="content.image_content">
                        <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'JPEG'" style="width: 35%;" :src="content.image_content">
                      </div>
                      <div>
                        <b-button
                          variant="outline-primary"
                          @click="getPrompt(x)"
                          class="mt-2"
                        >
                          Cambiar story
                        </b-button>
                      </div>
                      <b-modal
                        v-model="showPrompt"
                        ok-only
                        ok-title="Aceptar"
                        centered
                        title="Cambiar story"
                        @ok="changeStory(x)"
                      >
                        <b-form-file
                          placeholder="Cargar Story"
                          drop-placeholder="Drop file here..."
                          v-on:change="handleStoryUpload(x)"
                          accept=".jpeg, .jpg, .png, .mp4"
                          ref="story_content"
                          style="width: 75%; margin-right: 5%"
                          browse-text="Buscar"
                        />
                      </b-modal>
                    </div>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="EyeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de views"
                        v-model="content.number_of_views"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PrinterIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de impresiones"
                        v-model="content.number_of_impressions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HelpCircleIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de respuestas"
                        v-model="content.number_of_responses"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de clicks en el enlace"
                        v-model="content.number_of_clicks_to_the_link"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ShareIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de shares"
                        v-model="content.number_of_shares"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UsersIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de cuentas alcanzadas"
                        v-model="content.scope_number"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ActivityIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de interacciones totales"
                        v-model="content.number_of_interactions"
                      />
                    </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Foto'">
                  <div class="divider" />
                  <div
                    style="display: flex; flex-wrap:wrap;"
                  >
                    <b-input-group
                      style="width: 65%; margin-left: 10%; margin-right: 5%; margin-bottom:1em;"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el link de tu foto"
                        v-model="content.url_info"
                      />
                    </b-input-group>
                    <b-button
                      variant="success"
                      @click="metricsButton(x, content)"
                    >
                      Métricas
                    </b-button>
                  </div>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Video'">
                  <div class="divider" />
                  <div
                    style="display: flex;"
                  >
                    <b-input-group
                      style="width: 65%; margin-left: 10%; margin-right: 5%"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el link de tu foto"
                        v-model="content.url_info"
                      />
                    </b-input-group>
                    <b-button
                      variant="success"
                      @click="metricsButton(x, content)"
                      style="width: 10%"
                    >
                      Métricas
                    </b-button>
                  </div>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Streaming'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Reel'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu reel"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Instagram TV'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu instagram TV"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                </div>
                  <b-modal
                    v-model="modalShow"
                    cancel-variant="outline-secondary"
                    @ok="setSelectedPublishStory(selectPublishLink[x][y], selectPublishDate[x][y], content.uuid)"
                    cancel-title="Cerrar"
                    centered
                    size="xl"
                    title="Descargar story"
                  >
                    <div class="vx-row">
                        <b-row>
                          <b-col
                            md="12"
                            lg="3"
                            v-for="(stories, index) in instaStories" :key="index"
                          >
                            <video width="100%" controls v-if="stories.type == 'video'" class="responsive">
                                <source :src="stories.url" type="video/mp4">
                            </video>
                            <img :src="stories.url" alt="user-profile-cover" class="responsive mb-1" v-if="stories.type == 'img'" style="width: 100%">
                            <b-button
                              @click="downloadWithVueResource(stories.url,stories.type)"
                              variant="success"
                              style="width:100%"
                            >
                              Success
                            </b-button>
                          </b-col>
                        </b-row>
                    </div>
                  </b-modal>
              </b-col>
              <!-- TWITTER -->
              <div v-show="selectedRed[x].title == 'Twitter'" >
                <div v-show="selectedType[x].title == 'Post'">
                    <div class="divider" />
                    <div
                      style="display: flex;"
                    >
                      <b-input-group
                        style="width: 65%; margin-left: 10%; margin-right: 5%"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          placeholder="Ingresa el link de tu foto"
                          v-model="content.url_info"
                        />
                      </b-input-group>
                      <b-button
                        variant="success"
                        @click="metricsButton(x, content)"
                        style="width: 10%"
                      >
                        Métricas
                      </b-button>
                    </div>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PrinterIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de impresiones"
                        v-model="content.number_of_impressions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ThumbsUpIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de likes"
                        v-model="content.number_of_likes"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MessageCircleIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de comentarios"
                        v-model="content.number_of_comments"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ActivityIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de interacciones"
                        v-model="content.number_of_interactions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UsersIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de cuentas alcanzadas"
                        v-model="content.scope_number"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="RepeatIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de RTs"
                        v-model="content.number_of_rts"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HeartIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de favoritos"
                        v-model="content.number_of_times_favs"
                      />
                    </b-input-group>
                </div>
                <div v-show="selectedType[x].title == 'Foto'">
                    <div class="divider" />
                    <div
                      style="display: flex;"
                    >
                      <b-input-group
                        style="width: 65%; margin-left: 10%; margin-right: 5%"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          placeholder="Ingresa el link de tu foto"
                          v-model="content.url_info"
                        />
                      </b-input-group>
                      <b-button
                        variant="success"
                        @click="metricsButton(x, content)"
                        style="width: 10%"
                      >
                        Métricas
                      </b-button>
                    </div>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PrinterIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de impresiones"
                        v-model="content.number_of_impressions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ThumbsUpIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de likes"
                        v-model="content.number_of_likes"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MessageCircleIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de comentarios"
                        v-model="content.number_of_comments"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ActivityIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de interacciones"
                        v-model="content.number_of_interactions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UsersIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de cuentas alcanzadas"
                        v-model="content.scope_number"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ShareIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de shares"
                        v-model="content.number_of_shares"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="BookmarkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Número de veces guardadas"
                        v-model="content.number_of_times_saved"
                      />
                    </b-input-group>
                </div>
                <div v-show="selectedType[x].title == 'Video'">
                    <div class="divider" />
                    <div
                      style="display: flex;"
                    >
                      <b-input-group
                        style="width: 65%; margin-left: 10%; margin-right: 5%"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          placeholder="Ingresa el link de tu foto"
                          v-model="content.url_info"
                        />
                      </b-input-group>
                      <b-button
                        variant="success"
                        @click="metricsButton(x, content)"
                        style="width: 10%"
                      >
                        Métricas
                      </b-button>
                    </div>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="EyeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de views"
                        v-model="content.number_of_views"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PrinterIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de impresiones"
                        v-model="content.number_of_impressions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ThumbsUpIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de likes"
                        v-model="content.number_of_likes"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MessageCircleIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de comentarios"
                        v-model="content.number_of_comments"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ActivityIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de interacciones"
                        v-model="content.number_of_interactions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UsersIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de cuentas alcanzadas"
                        v-model="content.scope_number"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SharesIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de shares"
                        v-model="content.number_of_shares"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="BookmarkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Número de veces guardadas"
                        v-model="content.number_of_times_saved"
                      />
                    </b-input-group>
                </div>
                <div v-show="selectedType[x].title == 'Streaming'">
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el link de tu video"
                        v-model="content.url_info"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="EyeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de views"
                        v-model="content.number_of_views"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PrinterIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de impresiones"
                        v-model="content.number_of_impressions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PrinterIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de likes"
                        v-model="content.number_of_likes"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MessageCircleIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de comentarios"
                        v-model="content.number_of_comments"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ActivityIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de interacciones"
                        v-model="content.number_of_interactions"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UsersIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de cuentas alcanzadas"
                        v-model="content.scope_number"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ShareIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Ingresa el número de shares"
                        v-model="content.number_of_shares"
                      />
                    </b-input-group>
                    <div class="divider" />
                    <b-input-group
                      style="width: 80%;"
                      class="m-auto"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="BookmarkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Número de veces guardadas"
                        v-model="content.number_of_times_saved"
                      />
                    </b-input-group>
                </div>
              </div>
              <!-- FACEBOOK -->
              <div v-if="selectedRed[x].title == 'Facebook'" class="vx-row">
                <div v-if="selectedType[x].title == 'Streaming'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu streaming"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Post'">
                  <div class="divider" />
                    <div
                      style="display: flex;"
                    >
                      <b-input-group
                        style="width: 65%; margin-left: 10%; margin-right: 5%"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          placeholder="Ingresa el link de tu foto"
                          v-model="content.url_info"
                        />
                      </b-input-group>
                      <b-button
                        variant="success"
                        @click="metricsButton(x, content)"
                        style="width: 10%"
                      >
                        Métricas
                      </b-button>
                    </div>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Foto'">
                  <div class="divider" />
                    <div
                      style="display: flex; flex-wrap:wrap"
                    >
                      <b-input-group
                        style="width: 65%; margin-left: 10%; margin-right: 5%; margin-bottom:1em;"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          placeholder="Ingresa el link de tu foto"
                          v-model="content.url_info"
                        />
                      </b-input-group>
                      <b-button
                        variant="success"
                        @click="metricsButton(x, content)"
                      >
                        Métricas
                      </b-button>
                    </div>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Story'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu story"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HelpCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de respuestas"
                      v-model="content.number_of_responses"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Video'">
                  <div class="divider" />
                    <div
                      style="display: flex;"
                    >
                      <b-input-group
                        style="width: 65%; margin-left: 10%; margin-right: 5%"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LinkIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          placeholder="Ingresa el link de tu foto"
                          v-model="content.url_info"
                        />
                      </b-input-group>
                      <b-button
                        variant="success"
                        @click="metricsButton(x, content)"
                        style="width: 10%"
                      >
                        Métricas
                      </b-button>
                    </div>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>/>
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                </div>
              </div>
              <!-- YOUTUBE -->
              <div v-if="selectedRed[x].title == 'Youtube'" class="vx-row">
                <div v-show="selectedType[x].title == 'Video'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu video"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsDownIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de dislikes"
                      v-model="content.number_of_dislikes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                </div>
                <div v-show="selectedType[x].title == 'Streaming'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu streaming"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                </div>
              </div>
              <!-- TIKTOK -->
              <div v-if="selectedRed[x].title == 'Tiktok'">
                <div v-if="selectedType[x].title == 'Video'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu tiktok"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Streaming'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu streaming"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                </div>
              </div>
              <!-- LINKEDIN -->
              <div v-if="selectedRed[x].title == 'LinkedIn'">
                <div v-if="selectedType[x].title == 'Post'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu post"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Foto'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu foto"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Video'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu video"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                </div>
                <div v-if="selectedType[x].title == 'Streaming'">
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el link de tu streaming"
                      v-model="content.url_info"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PrinterIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de impresiones"
                      v-model="content.number_of_impressions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ThumbsUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de likes"
                      v-model="content.number_of_likes"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MessageCircleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de comentarios"
                      v-model="content.number_of_comments"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de shares"
                      v-model="content.number_of_shares"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ActivityIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de interacciones"
                      v-model="content.number_of_interactions"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BookmarkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Número de veces guardadas"
                      v-model="content.number_of_times_saved"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UsersIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de alcance"
                      v-model="content.scope_number"
                    />
                  </b-input-group>
                  <div class="divider" />
                  <b-input-group
                    style="width: 80%;"
                    class="m-auto"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Ingresa el número de views"
                      v-model="content.number_of_views"
                    />
                  </b-input-group>
                </div>
              </div>
              <!-- SPOTIFY -->
              <div v-if="selectedRed[x].title == 'Spotify'">
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el link de tu post"
                    v-model="content.url_info"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PlayIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de reproducciones"
                    v-model="content.number_of_reproductions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PrinterIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de impresiones"
                    v-model="content.number_of_impressions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ThumbsUpIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de likes"
                    v-model="content.number_of_likes"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MessageCircleIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de comentarios"
                    v-model="content.number_of_comments"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ShareIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de shares"
                    v-model="content.number_of_shares"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ActivityIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de interacciones"
                    v-model="content.number_of_interactions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="BookmarkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Número de veces guardadas"
                    v-model="content.number_of_times_saved"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UsersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de alcance"
                    v-model="content.scope_number"
                  />
                </b-input-group>
              </div>
              <!-- PINTEREST -->
              <div v-if="selectedRed[x].title == 'Pinterest'">
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el link de tu post"
                    v-model="content.url_info"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PrinterIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de impresiones"
                    v-model="content.number_of_impressions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ThumbsUpIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de likes"
                    v-model="content.number_of_likes"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MessageCircleIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de comentarios"
                    v-model="content.number_of_comments"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ShareIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de shares"
                    v-model="content.number_of_shares"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ActivityIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de interacciones"
                    v-model="content.number_of_interactions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="BookmarkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Número de veces guardadas"
                    v-model="content.number_of_times_saved"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UsersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de alcance"
                    v-model="content.scope_number"
                  />
                </b-input-group>
              </div>
              <!-- TWITCH -->
              <div v-if="selectedRed[x].title == 'Twitch'">
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el link de tu post"
                    v-model="content.url_info"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PrinterIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de impresiones"
                    v-model="content.number_of_impressions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ThumbsUpIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de likes"
                    v-model="content.number_of_likes"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MessageCircleIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de comentarios"
                    v-model="content.number_of_comments"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ShareIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de shares"
                    v-model="content.number_of_shares"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ActivityIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de interacciones"
                    v-model="content.number_of_interactions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="BookmarkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Número de veces guardadas"
                    v-model="content.number_of_times_saved"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UsersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de alcance"
                    v-model="content.scope_number"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="EyeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de views"
                    v-model="content.number_of_views"
                  />
                </b-input-group>
              </div>
              <!-- BLOG -->
              <div v-if="selectedRed[x].title == 'Blog'">
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el link de tu post"
                    v-model="content.url_info"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PrinterIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de impresiones"
                    v-model="content.number_of_impressions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MessageCircleIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de comentarios"
                    v-model="content.number_of_comments"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ShareIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de shares"
                    v-model="content.number_of_shares"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ActivityIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de interacciones"
                    v-model="content.number_of_interactions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UsersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de alcance"
                    v-model="content.scope_number"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ExternalLinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de clics al enlace"
                    v-model="content.number_of_clicks_to_the_link"
                  />
                </b-input-group>
              </div>
              <!-- SNAPCHAT  -->
              <div v-if="selectedRed[x].title == 'Snapchat'">
                  <div v-show="content.image_content == null" class="vx-col mr-auto ml-auto">
                      <vs-upload text="Cargar tu Snap" action="" limit="1" id="image_content" :ref="content.uuid" v-on:change="handleFileUpload(x)" accept=".jpeg, .jpg, .png, .mp4" required/>
                      <p v-show="errors[x].image_content" style="color: red; margin-left: 10%">{{imageText}}</p>
                  </div>
                  <div v-show="content.image_content != null" class="vx-col mr-auto ml-auto text-center">
                      <div>
                          <video v-show="content.image_content != null && getUrl(content.image_content) == 'mp4'" style="width: 50%;" :src="content.image_content" controls></video>
                          <video v-show="content.image_content != null && getUrl(content.image_content) == 'MP4'" style="width: 50%;" :src="content.image_content" controls></video>
                          <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'png'" style="width: 35%;" :src="content.image_content">
                          <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'PNG'" style="width: 35%;" :src="content.image_content">
                          <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'jpg'" style="width: 35%;" :src="content.image_content">
                          <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'JPG'" style="width: 35%;" :src="content.image_content">
                          <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'jpeg'" style="width: 35%;" :src="content.image_content">
                          <img class="m-auto" v-show="content.image_content != null && getUrl(content.image_content) == 'JPEG'" style="width: 35%;" :src="content.image_content">
                      </div>
                      <div>
                          <vs-button @click="getPrompt(x)" class="mt-4" color="primary" type="border">Cambiar snap</vs-button>
                      </div>
                      <vs-prompt
                          v-if="showPrompt"
                          title="Agregar Story"
                          accept-text="Cambiar"
                          cancel-text="Cancelar"
                          @cancel="showPrompt=false"
                          @accept="changeStory(x)"
                          @close="showPrompt=false"
                          :active.sync="activePrompt[x]">
                          <div class="con-exemple-prompt mr-auto ml-auto">
                              <vs-upload text="Cargar Story" action="" limit="1" id="image_content" ref="story_content" v-on:change="handleStoryUpload()" accept=".jpeg, .jpg, .png, .mp4"/>
                          </div>
                      </vs-prompt>
                      <!-- <vs-prompt
                      @accept="acceptAlert"
                      :active.sync="activePrompt">
                          <div class="con-exemple-prompt">
                              <span>Enter the security code</span>
                          <vs-input placeholder="Code" vs-placeholder="Code" v-model="val" class="mt-3 w-full" />
                          </div>
                      </vs-prompt> -->
                  </div>
                  <vs-divider/>
                  <p v-show="errors[x].number_of_views" style="color: red; margin-left: 10%">{{viewsText}}</p>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="EyeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de views"
                    v-model="content.number_of_views"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PrinterIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de impresiones"
                    v-model="content.number_of_impressions"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HelpCircleIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de respuestas"
                    v-model="content.number_of_responses"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de clicks en el enlace"
                    v-model="content.number_of_clicks_to_the_link"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ShareIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de shares"
                    v-model="content.number_of_shares"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UsersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de cuentas alcanzadas"
                    v-model="content.scope_number"
                  />
                </b-input-group>
                <div class="divider" />
                <b-input-group
                  style="width: 80%;"
                  class="m-auto"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ActivityIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Ingresa el número de interacciones totales"
                    v-model="content.number_of_interactions"
                  />
                </b-input-group>
              </div>
            </b-col>
            <b-button
               v-show="content.url_info == null && content.image_content == null"
              variant="success"
              class="mr-auto ml-auto mt-3"
              @click="approvedButton(x,content, campaignInfo.campaign_influencer.uuid)"
            >
              Guardar
            </b-button>
            <b-button
               v-show="content.url_info != null || content.image_content != null"
              variant="success"
              class="mr-auto ml-auto mt-3"
              @click="updateInformation(x,content)"
            >
              Actualizar
            </b-button>
          </b-row>
        </b-card>
        <div
          style="text-align: center"
        >
          <b-button
            variant="primary"
            @click="addExtraContent(campaignInfo.campaign_influencer.uuid)"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span class="align-middle">{{$t('campaigns.addContent')}}</span>
          </b-button>
        </div>
      </b-tab>
    </b-tabs>

    <p class="clearfix m-2">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        <b-img
          src="@/assets/images/logo/brandme_logo_gray.png"
          alt="Logo"
          style="max-width: 75px"
        />
        {{ new Date().getFullYear() }} ©
        <span class="d-none d-sm-inline-block">BrandMe</span>
      </span>

      <span class="float-md-right d-none d-md-block spanIcons">
        <div class="d-flex">
          <b-link
            href="/terminos-y-condiciones"
            target="_blank"
            class="blinkfoot"
          >
            {{ $t("footer.conditions") }}  |
          </b-link>
         
          <b-link
            href="/terminos-y-condiciones"
            target="_blank"
            class="blinkfoot ml-25"
          >
            {{ $t("footer.privacy") }}
          </b-link>
          <b-link
            class="ml-75"
            href="https://www.instagram.com/brandme/"
            target="_blank"
          >
            <span
              class="icomoon-instagram1 instagram blinkfoot"
              style="margin: 0 4px; font-size: 1.3em"
            ></span>
          </b-link>
          <b-link
            class="ml-25 twitterIcon"
            href="https://twitter.com/brandme"
            target="_blank"
          >
            <span
              class="icomoon-Twitter-1 twitter blinkfoot block"
              style="margin: 0 5px; font-size: 1.4em"
            ></span>
          </b-link>
          <b-link
            href="https://www.facebook.com/brandme"
            target="_blank"
          >
            <span
              class="icomoon-Facebook-2 facebook blinkfoot"
              style="margin: 0 3px; font-size: 1.3em"
            ></span>
          </b-link>
          <b-link
            class="ml-25 linkedinIcon"
            href="https://www.youtube.com/channel/UC-xugPKj2TCraqbTCNnme9w"
            target="_blank"
          >
            <span
              class="icomoon-Youtube-1 youtube blinkfoot youtubeIcon"
            ></span>
          </b-link>

          <b-link
            class="ml-50 linkedinIcon"
            href="https://www.linkedin.com/company/brandme_/"
            target="_blank"
          >
            <span
              class="icomoon-Linkedin linkedin blinkfoot"
              style="margin: 0 4px; font-size: 1.3em"
            ></span>
          </b-link>

          <b-link
            class="ml-25"
            href="https://www.tiktok.com/@brandme_"
            target="_blank"
          >
            <span
              class="icomoon-tiktok instagram blinkfoot"
              style="margin: 0 4px; font-size: 1.3em"
            ></span>
          </b-link>
        </div>
      </span>
    </p>
  </div>
</template>

<script>
import {
  BImg, BCard, BRow, BCol, BAvatar, BTabs, BTab, BInputGroup, BFormInput, BInputGroupPrepend, BButton, BFormFile, BModal, VBModal, BLink
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import config from '@/axiosCode'
import utils from '@/libs/utils'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTabs,
    BTab,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BFormFile,
    BModal,
    BLink
  },
  directives: {
    'b-modal': VBModal
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      dominio: window.location.host,
      campaignInfo: {},
      cover: '',
      logo: '',
      name: '',
      custom_fields: [],
      componentKey: 0,
      sumContent: 0,
      ToastificationContent,
      instaStories: [],
      modalShow: false,
      showPrompt: false
    }
  },
  async created () {
    this.checkInvitation()
    const vue = this
    const url_service = this.$route.params.uuid ? `${config.apiCore}/api/v1/campaign/${vue.$route.params.uuid}/external/${vue.$route.params.uuidInfluencer}` : `${config.apiCore}/api/v1/campaign/external/${this.$route.params.slug}/${this.$route.params.network}/${this.$route.params.username}/`
    this.$http.get(url_service)
      .then(response => {
        console.log('heeeeey', response)
        vue.campaignInfo = response.data.response ? response.data.response : {}
        vue.custom_fields = vue.orderCustomFields(vue.campaignInfo.custom_fields)
        vue.cover = response.data.response.cover
        vue.logo = response.data.response.logo
        vue.name = response.data.response.name
        vue.selectedRed = vue.getSelectedRed(response.data.response.campaign_influencer.content)
        vue.selectedType = vue.getSelectedType(response.data.response.campaign_influencer.content)
        vue.optionsRed = vue.getOptionsRed(response.data.response.campaign_influencer.content)
        vue.optionsType = vue.getOptionsType(response.data.response.campaign_influencer.content)
        vue.image_content = vue.getImageContent(response.data.response.campaign_influencer.content)

        // Get from instagram
        this.$http.get(`${config.apiCore}/api/v1/campaign/${response.data.response.uuid}/stories/${response.data.response.campaign_influencer.influencer.username}`)
          .then(response_insta => {
            vue.stories = response_insta.data.response.stories
            vue.renderComponent = true
          // vue.influencerHtml = "<div class='row'>"+response_insta.data.response.html+"</div>"
          //this.forceRerender()
          })
        // .then(
        //     document.querySelectorAll(".listings-grid__main img").forEach(el => el.style.width = '200px');
        //     document.querySelectorAll(".listings-grid__main video").forEach(el => el.style.width = '200px');
        // )
          .catch(error_insta => {
            console.log('catch created')
            console.log(error_insta)
          })
        vue.sumContent = vue.sumContents(response.data.response.campaign_influencer.content)
        vue.activePrompt = vue.getPromptArray(response.data.response.campaign_influencer.content)
        vue.errors = vue.getErrorsArray(response.data.response.campaign_influencer.content)
        // vue.imgV = vue.getUrl(response.data.response.campaign_influencer.content)
        // vue.imgV = vue.getUrl(response.data.response.campaign_influencer.content)


      })
      .catch(error => {
        console.log('catch created 2')
        console.log(error)
      })
  },
  methods: {
    checkInvitation() {
      if (utils.isInvitation()) {
        setTimeout(() => {
          document.getElementById('hubspot-messages-iframe-container').classList.add('hidden')
        }, 500)
      }
    },
    getImage(influencer) {
      if (influencer.file_for_profile) return utils.getAssetUrl(influencer.file_for_profile)
      else if (influencer.profile_url) return influencer.profile_url
      return influencer.influencer.image_url
    },
    getCustomFields() {
      return this.custom_fields.filter(custom => custom.show_link);
    },
    getBackGroud() {
      return this.dominio.includes('coca') ? '#f01f33' : 'linear-gradient(120deg ,rgba(151,51,102,1), rgba(115,62,126,1))'
    },
    checkCoca() {
      return this.dominio.includes('coca')
    },
    orderCustomFields(custom_fields) {
      custom_fields.sort(function(a, b) {
        return a.order - b.order;
      });
      return custom_fields
    },
    getSelectedRed (content) {
      console.log('entro a getSelectedRed')
      const result = []
      let x = 0
      content.forEach(i => {
        if (i.network === 'instagram') {
          result[x] = {title: 'Instagram', uuid: i.uuid}
        }
        if (i.network === 'youtube') {
          result[x] = {title: 'Youtube', uuid: i.uuid}
        }
        if (i.network === 'tiktok') {
          result[x] = {title: 'Tiktok', uuid: i.uuid}
        }
        if (i.network === 'twitter') {
          result[x] = {title: 'Twitter', uuid: i.uuid}
        }
        if (i.network === 'facebook') {
          result[x] = {title: 'Facebook', uuid: i.uuid}
        }
        if (i.network === 'linkedin') {
          result[x] = {title: 'Linkedin', uuid: i.uuid}
        }
        if (i.network === 'spotify') {
          result[x] = {title: 'Spotify', uuid: i.uuid}
        }
        if (i.network === 'twitch') {
          result[x] = {title: 'Twitch', uuid: i.uuid}
        }
        if (i.network === 'pinterest') {
          result[x] = {title: 'Pinterest', uuid: i.uuid}
        }
        if (i.network === 'blog') {
          result[x] = {title: 'Blog', uuid: i.uuid}
        }
        if (i.network === 'snapchat') {
          result[x] = {title: 'Snapchat', uuid: i.uuid}
        }
        x += 1
      })
      return result
    },
    getSelectedType (content) {
      const result = []
      let x = 0
      content.forEach(i => {
        if (i.content_type === 'photo') {
          result[x] = {title: 'Foto', uuid: i.uuid}
        }
        if (i.content_type === 'video') {
          result[x] = {title: 'Video', uuid: i.uuid}
        }
        if (i.content_type === 'story') {
          result[x] = {title: 'Story', uuid: i.uuid}
        }
        if (i.content_type === 'snap') {
          result[x] = {title: 'Snap', uuid: i.uuid}
        }
        if (i.content_type === 'streaming') {
          result[x] = {title: 'Streaming', uuid: i.uuid}
        }
        if (i.content_type === 'post') {
          result[x] = {title: 'Post', uuid: i.uuid}
        }
        if (i.content_type === 'instagram_tv') {
          result[x] = {title: 'Instagram TV', uuid: i.uuid}
        }
        if (i.content_type === 'reel') {
          result[x] = {title: 'Reel', uuid: i.uuid}
        }
        x += 1
        this.forceRerender()
      })
      return result
    },
    getOptionsRed (content) {
      const result = []
      let x = 0
      content.forEach(i => {
        result[x] = [
          {title: 'Instagram', uuid: i.uuid},
          {title: 'Youtube', uuid: i.uuid},
          {title: 'Tiktok', uuid: i.uuid},
          {title: 'Twitter', uuid: i.uuid},
          {title: 'Facebook', uuid: i.uuid},
          {title: 'LinkedIn', uuid: i.uuid},
          {title: 'Spotify', uuid: i.uuid},
          {title: 'Twitch', uuid: i.uuid},
          {title: 'Pinterest', uuid: i.uuid},
          {title: 'Blog', uuid: i.uuid},
          {title: 'Snapchat', uuid: i.uuid}
        ]
        x += 1
      })
      return result
    },
    getOptionsType (content) {
      const result = []
      let x = 0
      content.forEach(i => {
        if (i.network === 'instagram') {
          result[x] = [
            {title: 'Foto', uuid: i.uuid},
            {title: 'Video', uuid: i.uuid},
            {title: 'Story', uuid: i.uuid},
            {title: 'Streaming', uuid: i.uuid},
            {title: 'Instagram TV', uuid: i.uuid},
            {title: 'Reel', uuid: i.uuid}
          ]
        }
        if (i.network === 'tiktok') {
          result[x] = [
            {title: 'Video', uuid: i.uuid},
            {title: 'Streaming', uuid: i.uuid}
          ]
        }
        if (i.network === 'youtube') {
          result[x] = [
            {title: 'Video', uuid: i.uuid},
            {title: 'Streaming', uuid: i.uuid}
          ]
        }
        if (i.network === 'facebook') {
          result[x] = [
            {title: 'Foto', uuid: i.uuid},
            {title: 'Video', uuid: i.uuid},
            {title: 'Story', uuid: i.uuid},
            {title: 'Streaming', uuid: i.uuid},
            {title: 'Post', uuid: i.uuid}
          ]
        }
        if (i.network === 'linkedin') {
          result[x] = [
            {title: 'Foto', uuid: i.uuid},
            {title: 'Video', uuid: i.uuid},
            {title: 'Streaming', uuid: i.uuid},
            {title: 'Post', uuid: i.uuid}
          ]
        }
        if (i.network === 'twitter') {
          result[x] = [
            {title: 'Foto', uuid: i.uuid},
            {title: 'Video', uuid: i.uuid},
            {title: 'Streaming', uuid: i.uuid},
            {title: 'Post', uuid: i.uuid}
          ]
        }
        if (i.network === 'snapchat') {
          result[x] = {title: 'Snap', uuid: i.uuid}
        }
        if (i.network === 'spotify' || i.network === 'pinterest' || i.network === 'blog' || i.network === 'twitch') {
          result[x] = {title: 'Post', uuid: i.uuid}
        }
        x += 1
        this.forceRerender()
      })
      return result
    },
    getImageContent (content) {
      const result = []
      let x = 0
      content.forEach(i => {
        console.log(i)
        result[x] = {}
        x += 1
      })
      return result
    },
    sumContents (info) {
      let result = 0
      info.forEach(i => {
        console.log(i)
        result += 1
      })
      return result
    },
    getPromptArray (info) {
      const result = []
      let x = 0
      info.forEach(i => {
        console.log(i)
        result[x] = false
        x += 1
      })
      return result
    },
    getErrorsArray (content) {
      const result = []
      let x = 0
      content.forEach(i => {
        console.log(i)
        result[x] = {
          image_content: '',
          number_of_clicks_to_the_link: '',
          number_of_impressions: '',
          number_of_interactions: '',
          number_of_responses: '',
          number_of_shares: '',
          number_of_views: '',
          scope_number: '',
          url_info: '',
          number_of_likes: '',
          number_of_comments: '',
          number_of_times_saved: '',
          number_of_rts: '',
          number_of_times_favs: '',
          number_of_dislikes: '',
          number_of_reproductions: '',
          number_of_clicks_to_stickers: ''
        }
        x++
      })
      return result
    },
    loadTypes (x) {
      const network = this.selectedRed[x].title
      const uuid = this.campaignInfo.campaign_influencer.content[x].uuid
      const vue = this
      if (network === 'Instagram') {
        vue.optionsType[x] = [
          {title: 'Foto', uuid},
          {title: 'Video', uuid},
          {title: 'Story', uuid},
          {title: 'Streaming', uuid},
          {title: 'Instagram TV', uuid},
          {title: 'Reel', uuid}
        ]
        vue.selectedType[x] = {title: 'Story', uuid}
      }
      if (network === 'Tiktok') {
        vue.optionsType[x] = [
          {title: 'Video', uuid},
          {title: 'Streaming', uuid}
        ]
        vue.selectedType[x] = {title: 'Video', uuid}
      }
      if (network === 'Youtube') {
        vue.optionsType[x] = [
          {title: 'Video', uuid},
          {title: 'Streaming', uuid}
        ]
        vue.selectedType[x] = {title: 'Video', uuid}
      }
      if (network === 'Facebook') {
        vue.optionsType[x] = [
          {title: 'Foto', uuid},
          {title: 'Video', uuid},
          {title: 'Story', uuid},
          {title: 'Streaming', uuid},
          {title: 'Post', uuid}
        ]
        vue.selectedType[x] = {title: 'Foto', uuid}
      }
      if (network === 'LinkedIn') {
        vue.optionsType[x] = [
          {title: 'Foto', uuid},
          {title: 'Video', uuid},
          {title: 'Streaming', uuid},
          {title: 'Post', uuid}
        ]
        vue.selectedType[x] = {title: 'Foto', uuid}
      }
      if (network === 'Twitter') {
        vue.optionsType[x] = [
          {title: 'Foto', uuid},
          {title: 'Video', uuid},
          // {title: 'Tweet', uuid},
          {title: 'Streaming', uuid},
          {title: 'Post', uuid}
        ]
        vue.selectedType[x] = {title: 'Foto', uuid}
      }
      if (network === 'Spotify' || network === 'Pinterest' || network === 'Blog' || network === 'Twitch') {
        vue.optionsType[x] = [{title: 'Post', uuid}]
        vue.selectedType[x] = {title: 'Post', uuid}
      }
      console.log(vue.selectedType[x])
      console.log(vue.optionsType[x])
      this.forceRerender()
    },
    refreshInsta () {
      setTimeout(function () {
        document.querySelectorAll('.listings-grid__main img').forEach(el => { el.style.width = '200px' })
        document.querySelectorAll('.listings-grid__main video').forEach(el => { el.style.width = '200px' })
      }, 3000)
    },
    forceRerender () {
      this.componentKey += 1
    },
    approvedButton (x, content, campaignInfluencer) {
      const formData = new FormData()
      const vue = this
      if (this.selectedRed[x].title === 'Twitter') {
        if (this.selectedType[x].title === 'Post') {
          formData.append('network', 'twitter')
          formData.append('status', content.status)
          formData.append('content_type', 'post')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_favs', content.number_of_times_favs)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_rts', content.number_of_rts)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'twitter')
          formData.append('status', content.status)
          formData.append('content_type', 'photo')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'twitter')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_views', content.number_of_views)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'twitter')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_views', content.number_of_views)
        }
      }
      if (this.selectedRed[x].title === 'Facebook') {
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'photo')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('scope_number', content.scope_number)
        }
        if (this.selectedType[x].title === 'Post') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'post')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Story') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'story')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_responses', content.number_of_responses)
          formData.append('number_of_views', content.number_of_views)
        }
      }
      if (this.selectedRed[x].title === 'Youtube') {
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'youtube')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_dislikes', content.number_of_dislikes)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('scope_number', content.scope_number)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'youtube')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
        }
      }
      if (this.selectedRed[x].title === 'Instagram') {
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'photo')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Reel') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'reel')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('scope_number', content.scope_number)
        }
        if (this.selectedType[x].title === 'Instagram TV') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'instagram_tv')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
        }
        if (this.selectedType[x].title === 'Story') {
          if (Object.keys(vue.image_content[x]).length > 0) formData.append('image_content', vue.image_content[x])
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'story')
          formData.append('campaign_influencer', campaignInfluencer)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_responses', content.number_of_responses)
          formData.append('number_of_clicks_to_the_link', content.number_of_clicks_to_the_link)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          // formData.append('number_of_clicks_to_stickers', content.number_of_clicks_to_stickers)
          console.log(vue.campaign_influencer)
        }
      }
      if (this.selectedRed[x].title === 'Tiktok') {
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'tiktok')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('scope_number', content.scope_number)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'tiktok')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_interactions', content.number_of_interactions)
        }
      }
      if (this.selectedRed[x].title === 'Linkedin') {
        if (this.selectedType[x].title === 'Post') {
          formData.append('network', 'linkedin')
          formData.append('status', content.status)
          formData.append('content_type', 'post')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'linkedin')
          formData.append('status', content.status)
          formData.append('content_type', 'photo')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'linkedin')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_views', content.number_of_views)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'linkedin')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_views', content.number_of_views)
        }
      }
      if (this.selectedRed[x].title === 'Spotify') {
        formData.append('network', 'spotify')
        formData.append('status', content.status)
        formData.append('content_type', 'post')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('url_info', content.url_info)
        formData.append('number_of_likes', content.number_of_likes)
        formData.append('number_of_comments', content.number_of_comments)
        formData.append('number_of_interactions', content.number_of_interactions)
        formData.append('number_of_times_saved', content.number_of_times_saved)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_impressions', content.number_of_impressions)
        formData.append('number_of_reproductions', content.number_of_reproductions)
      }
      if (this.selectedRed[x].title === 'Pinterest') {
        formData.append('network', 'pinterest')
        formData.append('status', content.status)
        formData.append('content_type', 'photo')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('url_info', content.url_info)
        formData.append('number_of_likes', content.number_of_likes)
        formData.append('number_of_comments', content.number_of_comments)
        formData.append('number_of_interactions', content.number_of_interactions)
        formData.append('number_of_times_saved', content.number_of_times_saved)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_impressions', content.number_of_impressions)
      }
      if (this.selectedRed[x].title === 'Twitch') {
        formData.append('network', 'twitch')
        formData.append('status', content.status)
        formData.append('content_type', 'streaming')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('url_info', content.url_info)
        formData.append('number_of_likes', content.number_of_likes)
        formData.append('number_of_comments', content.number_of_comments)
        formData.append('number_of_interactions', content.number_of_interactions)
        formData.append('number_of_times_saved', content.number_of_times_saved)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_impressions', content.number_of_impressions)
        formData.append('number_of_views', content.number_of_views)
      }
      if (this.selectedRed[x].title === 'Blog') {
        formData.append('network', 'blog')
        formData.append('status', content.status)
        formData.append('content_type', 'post')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('url_info', content.url_info)
        formData.append('number_of_comments', content.number_of_comments)
        formData.append('number_of_interactions', content.number_of_interactions)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_impressions', content.number_of_impressions)
        formData.append('number_of_clicks_to_the_link', content.number_of_clicks_to_the_link)
      }
      if (this.selectedRed[x].title === 'Snapchat') {
        if (Object.keys(vue.image_content[x]).length > 0) formData.append('image_content', vue.image_content[x])
        formData.append('network', 'snapchat')
        formData.append('status', content.status)
        formData.append('content_type', 'snap')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('number_of_impressions', content.number_of_impressions)
        formData.append('number_of_responses', content.number_of_responses)
        formData.append('number_of_clicks_to_the_link', content.number_of_clicks_to_the_link)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_views', content.number_of_views)
        formData.append('number_of_interactions', content.number_of_interactions)
      }
      const self = this
      const auth = {
        Authorization: `Bearer ${localStorage.token}`
      }

      this.$http.post(`${config.apiCore}/api/v1/campaign/${this.campaignInfo.uuid}/manage/influencer-add/`,
        formData,
        {
          headers: auth
        })
        .then(response => {
          console.log(response)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Información agregada',
              icon: 'BellIcon',
              text: 'La información del contenido se ha agregado con éxito.',
              variant: 'success'
            }
          })
        })
        .catch(response => {
          console.log(response)
          const e = response.response.data.response.message
          if (e.image_content) {
            self.errors[x].image_content = e.image_content[0]
          }
          if (e.number_of_clicks_to_the_link) {
            self.errors[x].number_of_clicks_to_the_link = e.number_of_clicks_to_the_link[0]
          }
          if (e.number_of_impressions) {
            self.errors[x].number_of_impressions = e.number_of_impressions[0]
          }
          if (e.number_of_interactions) {
            self.errors[x].number_of_interactions = e.number_of_interactions[0]
          }
          if (e.number_of_responses) {
            self.errors[x].number_of_responses = e.number_of_responses[0]
          }
          if (e.number_of_shares) {
            self.errors[x].number_of_shares = e.number_of_shares[0]
          }
          if (e.number_of_views) {
            self.errors[x].number_of_views = e.number_of_views[0]
          }
          if (e.scope_number) {
            self.errors[x].scope_number = e.scope_number[0]
          }
          if (e.url_info) {
            self.errors[x].url_info = e.url_info[0]
          }
          if (e.number_of_likes) {
            self.errors[x].number_of_likes = e.number_of_likes[0]
          }
          if (e.number_of_comments) {
            self.errors[x].number_of_comments = e.number_of_comments[0]
          }
          if (e.number_of_times_saved) {
            self.errors[x].number_of_times_saved = e.number_of_times_saved[0]
          }
          if (e.number_of_rts) {
            self.errors[x].number_of_rts = e.number_of_rts[0]
          }
          if (e.number_of_times_favs) {
            self.errors[x].number_of_times_favs = e.number_of_times_favs[0]
          }
          if (e.number_of_dislikes) {
            self.errors[x].number_of_dislikes = e.number_of_dislikes[0]
          }
          if (e.number_of_reproductions) {
            self.errors[x].number_of_reproductions = e.number_of_reproductions[0]
          }
        })
    },
    updateInformation (x, content) {
      const formData = new FormData()
      const vue = this
      if (this.selectedRed[x].title === 'Twitter') {
        if (this.selectedType[x].title === 'Post') {
          formData.append('network', 'twitter')
          formData.append('status', content.status)
          formData.append('content_type', 'post')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_favs', content.number_of_times_favs)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_rts', content.number_of_rts)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'twitter')
          formData.append('status', content.status)
          formData.append('content_type', 'photo')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'twitter')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_views', content.number_of_views)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'twitter')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_views', content.number_of_views)
        }
      }
      if (this.selectedRed[x].title === 'Facebook') {
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'photo')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('scope_number', content.scope_number)
        }
        if (this.selectedType[x].title === 'Post') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'post')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Story') {
          formData.append('network', 'facebook')
          formData.append('status', content.status)
          formData.append('content_type', 'story')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_responses', content.number_of_responses)
          formData.append('number_of_views', content.number_of_views)
        }
      }
      if (this.selectedRed[x].title === 'Youtube') {
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'youtube')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_dislikes', content.number_of_dislikes)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('scope_number', content.scope_number)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'youtube')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
        }
      }
      if (this.selectedRed[x].title === 'Instagram') {
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'photo')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Reel') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'reel')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('scope_number', content.scope_number)
        }
        if (this.selectedType[x].title === 'Instagram TV') {
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'instagram_tv')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
        }
        if (this.selectedType[x].title === 'Story') {
          if (Object.keys(vue.image_content[x]).length > 0) formData.append('image_content', vue.image_content[x])
          formData.append('network', 'instagram')
          formData.append('status', content.status)
          formData.append('content_type', 'story')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_responses', content.number_of_responses)
          formData.append('number_of_clicks_to_the_link', content.number_of_clicks_to_the_link)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_interactions', content.number_of_interactions)
          // formData.append('number_of_clicks_to_stickers', content.number_of_clicks_to_stickers)
        }
      }
      if (this.selectedRed[x].title === 'Tiktok') {
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'tiktok')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('scope_number', content.scope_number)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'tiktok')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_views', content.number_of_views)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_interactions', content.number_of_interactions)
        }
      }
      if (this.selectedRed[x].title === 'Linkedin') {
        if (this.selectedType[x].title === 'Post') {
          formData.append('network', 'linkedin')
          formData.append('status', content.status)
          formData.append('content_type', 'post')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('number_of_impressions', content.number_of_impressions)
        }
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'linkedin')
          formData.append('status', content.status)
          formData.append('content_type', 'photo')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'linkedin')
          formData.append('status', content.status)
          formData.append('content_type', 'video')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_views', content.number_of_views)
        }
        if (this.selectedType[x].title === 'Streaming') {
          formData.append('network', 'linkedin')
          formData.append('status', content.status)
          formData.append('content_type', 'streaming')
          formData.append('campaign_influencer', this.campaign_influencer)
          formData.append('url_info', content.url_info)
          formData.append('number_of_likes', content.number_of_likes)
          formData.append('number_of_comments', content.number_of_comments)
          formData.append('number_of_interactions', content.number_of_interactions)
          formData.append('number_of_shares', content.number_of_shares)
          formData.append('scope_number', content.scope_number)
          formData.append('number_of_impressions', content.number_of_impressions)
          formData.append('number_of_times_saved', content.number_of_times_saved)
          formData.append('number_of_views', content.number_of_views)
        }
      }
      if (this.selectedRed[x].title === 'Spotify') {
        formData.append('network', 'spotify')
        formData.append('status', content.status)
        formData.append('content_type', 'post')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('url_info', content.url_info)
        formData.append('number_of_likes', content.number_of_likes)
        formData.append('number_of_comments', content.number_of_comments)
        formData.append('number_of_interactions', content.number_of_interactions)
        formData.append('number_of_times_saved', content.number_of_times_saved)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_impressions', content.number_of_impressions)
        formData.append('number_of_reproductions', content.number_of_reproductions)
      }
      if (this.selectedRed[x].title === 'Pinterest') {
        formData.append('network', 'pinterest')
        formData.append('status', content.status)
        formData.append('content_type', 'photo')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('url_info', content.url_info)
        formData.append('number_of_likes', content.number_of_likes)
        formData.append('number_of_comments', content.number_of_comments)
        formData.append('number_of_interactions', content.number_of_interactions)
        formData.append('number_of_times_saved', content.number_of_times_saved)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_impressions', content.number_of_impressions)
      }
      if (this.selectedRed[x].title === 'Twitch') {
        formData.append('network', 'twitch')
        formData.append('status', content.status)
        formData.append('content_type', 'streaming')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('url_info', content.url_info)
        formData.append('number_of_likes', content.number_of_likes)
        formData.append('number_of_comments', content.number_of_comments)
        formData.append('number_of_interactions', content.number_of_interactions)
        formData.append('number_of_times_saved', content.number_of_times_saved)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_impressions', content.number_of_impressions)
        formData.append('number_of_views', content.number_of_views)
      }
      if (this.selectedRed[x].title === 'Blog') {
        formData.append('network', 'blog')
        formData.append('status', content.status)
        formData.append('content_type', 'post')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('url_info', content.url_info)
        formData.append('number_of_comments', content.number_of_comments)
        formData.append('number_of_interactions', content.number_of_interactions)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_impressions', content.number_of_impressions)
        formData.append('number_of_clicks_to_the_link', content.number_of_clicks_to_the_link)
      }
      if (this.selectedRed[x].title === 'Snapchat') {
        if (Object.keys(vue.image_content[x]).length > 0) formData.append('image_content', vue.image_content[x])
        formData.append('network', 'snapchat')
        formData.append('status', content.status)
        formData.append('content_type', 'snap')
        formData.append('campaign_influencer', this.campaign_influencer)
        formData.append('number_of_impressions', content.number_of_impressions)
        formData.append('number_of_responses', content.number_of_responses)
        formData.append('number_of_clicks_to_the_link', content.number_of_clicks_to_the_link)
        formData.append('scope_number', content.scope_number)
        formData.append('number_of_shares', content.number_of_shares)
        formData.append('number_of_views', content.number_of_views)
        formData.append('number_of_interactions', content.number_of_interactions)
      }
      const self = this
      this.$http.post(`${config.apiCore}/api/v1/campaign/${this.campaignInfo.uuid}/manage/influencer-update/${content.uuid}`,
        formData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Información actualizada',
              icon: 'BellIcon',
              text: 'La información se ha actualizado con éxito.',
              variant: 'success'
            }
          })
        })
        .catch(response => {
          const e = response.response.data.response.message
          if (e.image_content) {
            self.errors[x].image_content = e.image_content[0]
          }
          if (e.number_of_clicks_to_the_link) {
            self.errors[x].number_of_clicks_to_the_link = e.number_of_clicks_to_the_link[0]
          }
          if (e.number_of_impressions) {
            self.errors[x].number_of_impressions = e.number_of_impressions[0]
          }
          if (e.number_of_interactions) {
            self.errors[x].number_of_interactions = e.number_of_interactions[0]
          }
          if (e.number_of_responses) {
            self.errors[x].number_of_responses = e.number_of_responses[0]
          }
          if (e.number_of_shares) {
            self.errors[x].number_of_shares = e.number_of_shares[0]
          }
          if (e.number_of_views) {
            self.errors[x].number_of_views = e.number_of_views[0]
          }
          if (e.scope_number) {
            self.errors[x].scope_number = e.scope_number[0]
          }
          if (e.url_info) {
            self.errors[x].url_info = e.url_info[0]
          }
          if (e.number_of_likes) {
            self.errors[x].number_of_likes = e.number_of_likes[0]
          }
          if (e.number_of_comments) {
            self.errors[x].number_of_comments = e.number_of_comments[0]
          }
          if (e.number_of_times_saved) {
            self.errors[x].number_of_times_saved = e.number_of_times_saved[0]
          }
          if (e.number_of_rts) {
            self.errors[x].number_of_rts = e.number_of_rts[0]
          }
          if (e.number_of_times_favs) {
            self.errors[x].number_of_times_favs = e.number_of_times_favs[0]
          }
          if (e.number_of_dislikes) {
            self.errors[x].number_of_dislikes = e.number_of_dislikes[0]
          }
          if (e.number_of_reproductions) {
            self.errors[x].number_of_reproductions = e.number_of_reproductions[0]
          }
        })
    },
    metricsButton (x, content) {
      const formData = new FormData()
      if (this.selectedRed[x].title === 'Twitter') {
        if (this.selectedType[x].title === 'Post') {
          formData.append('network', 'twitter')
          formData.append('link', content.url_info)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'twitter')
          formData.append('link', content.url_info)
        }
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'twitter')
          formData.append('link', content.url_info)
        }
      }
      if (this.selectedRed[x].title === 'Facebook') {
        if (this.selectedType[x].title === 'Post') {
          formData.append('network', 'facebook')
          formData.append('link', content.url_info)
        }
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'facebook')
          formData.append('link', content.url_info)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'facebook')
          formData.append('link', content.url_info)
        }
      }
      if (this.selectedRed[x].title === 'Instagram') {
        if (this.selectedType[x].title === 'Foto') {
          formData.append('network', 'instagram')
          formData.append('link', content.url_info)
        }
        if (this.selectedType[x].title === 'Video') {
          formData.append('network', 'instagram')
          formData.append('link', content.url_info)
        }
      }
      const auth = {
        Authorization: `Bearer ${localStorage.token}`
      }
      this.$http.post(`${config.apiCore}/api/v1/campaign/${this.campaignInfo.uuid}/content/stats`,
        formData,
        {
          headers: auth
        })
        .then(response => {
          content.number_of_comments = response.data.response.comments
          content.number_of_likes = response.data.response.likes
          content.number_of_shares = response.data.response.shares
          content.number_of_views = response.data.response.views
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Métricas capturadas',
              icon: 'BellIcon',
              text: 'Se obtuvo las métricas del contenido con éxito',
              variant: 'success'
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    addExtraContent (campaign_influencer) {

      const influencer = campaign_influencer
      const formData = new FormData()
      formData.append('campaign_influencer', influencer)
      formData.append('network', 'instagram')
      formData.append('estimated_cost', 0)
      formData.append('status', 'content_pending')
      formData.append('content_type', 'photo')
      formData.append('content_date', '')
      formData.append('url_info', '')

      const self = this
      const auth = {
        Authorization: `Bearer ${localStorage.token}`
      }
      this.$http.post(`${config.apiCore}/api/v1/campaign/${this.campaignInfo.uuid}/manage/add/`,
        formData,
        {
          headers: auth
        })
        .then(response => {
          console.log(response)
          self.callAxios()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contenido agregado',
              icon: 'BellIcon',
              text: 'Se ha agregado un contenido con éxito.',
              variant: 'success'
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    callAxios () {
      const vue = this
      const url_service = this.$route.params.uuid ? `${config.apiCore}/api/v1/campaign/${vue.$route.params.uuid}/external/${vue.$route.params.uuidInfluencer}` : `${config.apiCore}/api/v1/campaign/external/${this.$route.params.slug}/${this.$route.params.network}/${this.$route.params.username}/`
      this.$http.get(url_service)
        .then(response => {
          vue.campaignInfo = response.data.response ? response.data.response : {}
          vue.cover = response.data.response.cover
          vue.logo = response.data.response.logo
          vue.name = response.data.response.name
          vue.selectedRed = vue.getSelectedRed(response.data.response.campaign_influencer.content)
          vue.selectedType = vue.getSelectedType(response.data.response.campaign_influencer.content)
          vue.optionsRed = vue.getOptionsRed(response.data.response.campaign_influencer.content)
          vue.optionsType = vue.getOptionsType(response.data.response.campaign_influencer.content)
          vue.image_content = vue.getImageContent(response.data.response.campaign_influencer.content)

          // Get from instagram
          this.$http.get(`${config.apiCore}/api/v1/campaign/${response.data.response.uuid}/stories/${response.data.response.campaign_influencer.influencer.username}`)
            .then(response_insta => {
              vue.stories = response_insta.data.response.stories
              vue.renderComponent = true
            // vue.influencerHtml = "<div class='row'>"+response_insta.data.response.html+"</div>"
            //this.forceRerender()
            })
          // .then(
          //     document.querySelectorAll(".listings-grid__main img").forEach(el => el.style.width = '200px');
          //     document.querySelectorAll(".listings-grid__main video").forEach(el => el.style.width = '200px');
          // )
            .catch(error_insta => {
              console.log('catch created')
              console.log(error_insta)
            })
          vue.sumContent = vue.sumContents(response.data.response.campaign_influencer.content)
          vue.activePrompt = vue.getPromptArray(response.data.response.campaign_influencer.content)
          vue.errors = vue.getErrorsArray(response.data.response.campaign_influencer.content)
          // vue.imgV = vue.getUrl(response.data.response.campaign_influencer.content)
          // vue.imgV = vue.getUrl(response.data.response.campaign_influencer.content)


        })
        .catch(error => {
          console.log('catch created 2')
          console.log(error)
        })
      
    },
    linkStory (uuid, x) {
      this.modalShow = !this.modalShow
      const vue = this
      const url_service = this.$route.params.uuid ? `${config.apiCore}/api/v1/campaign/${vue.$route.params.uuid}/external/${vue.$route.params.uuidInfluencer}` : `${config.apiCore}/api/v1/campaign/external/${this.$route.params.slug}/${this.$route.params.network}/${this.$route.params.username}/`
      this.$http.get(url_service)
        .then(response => {
          vue.inf = response.data ? response.data : {}
          this.$http.get(`${config.apiCore}/api/v1/campaign/${response.data.response.uuid}/stories/${response.data.response.campaign_influencer.influencer.username}`)
            .then(response_insta => {
              vue.instaStories = response_insta.data.response.stories
              vue.activeSidebar[x] = true
            }).then(() => {
            })
            .catch((error_insta) => {
              console.log(error_insta)
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    downloadWithVueResource (url, type) {
      this.urlType = type
      this.$http.get(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET',
          'Access-Control-Allow-Headers':'*'
        }
      }, {
        responseType: 'arraybuffer'
      })
        .then(response => {
          this.forceFileDownload(response)  
        })
        .catch(error => {
          console.log(error)
        })
      
    },
    forceFileDownload (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      if (this.urlType === 'img') {
        link.setAttribute('download', 'story.jpg')
      }
      if (this.urlType === 'video') {
        link.setAttribute('download', 'story.mp4')
      }
      document.body.appendChild(link)
      link.click()
    },
    getUrl (info) {
      const split = info.split('.').pop()
      return split
    },
    getPrompt (x) {
      console.log(x)
      this.showPrompt = !this.showPrompt
    },
    changeStory (x) {
      console.log('entro')
      let obj = {}
      const info = this.campaignInfo.campaign_influencer.content
      console.log(this.campaignInfo)
      console.log(info)
      const vue = this
      const uuid = info[x].uuid
      console.log(uuid)
      info.forEach(i => {
        if (i.uuid === uuid) {
          obj = i
        }
      })
      console.log(obj)
      console.log(vue.story_content)
      const formData = new FormData()
      formData.append('network', 'instagram')
      formData.append('status', obj.status)
      formData.append('content_type', 'story')
      formData.append('campaign_influencer', vue.campaignInfo.campaign_influencer)
      formData.append('image_content', vue.story_content)

      const self = this
      const auth = {
        Authorization: `Bearer ${localStorage.token}`
      }
      this.$http.post(`${config.apiCore}/api/v1/campaign/${this.campaignInfo.uuid}/manage/influencer-update/${uuid}`,
        formData,
        {
          headers: auth
        })
        .then(response => {
          console.log(response)
          self.callAxios()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Story modificada',
              icon: 'BellIcon',
              text: 'La story fue modificada con éxito.',
              variant: 'success'
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleFileUpload (x) {
      this.image_content[x] = this.$refs.image_content[x].$el.firstChild.files[0]
    },
    handleStoryUpload (x) {
      console.log(this.$refs.story_content[x])
      this.story_content = this.$refs.story_content[x].$el.firstChild.files[0]
      console.log(this.story_content)
    }
  }
}
</script>

<style>
.heightCover .card-img-top{
  max-height: 200px !important;
  object-fit: cover;
}
.heightCover .card-profile .card-body .profile-image-wrapper .profile-image img{
  height: 150px !important;
  width: 150px !important;
}
.heightCover .card-profile .card-body{
  padding: 9rem 2.3rem 1.5rem 2.3rem;
}

.changeTitle{
  position:absolute; 
  top: -5px; 
  right: -50px; 
  cursor: pointer;
}
.plusGrand{
  font-size: 16px;
}

.texto-vertical-2 {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

.divider{
  border-bottom: 1px solid #ddd;
  margin: 1.5% 0
}
.spanIcons {
  margin-right: 6em;
}
</style>
